/* Conturi.css */
.conturi {
  padding: 20px;
}

.conturi h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.conturi-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff; /* White background for tables */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.conturi-table th,
.conturi-table td {
  border: 1px solid #ddd;
  padding: 12px 15px; /* Increase padding for better spacing */
  text-align: left;
}

.conturi-table th {
  background-color: #007bff; /* Modern blue header */
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.conturi-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Alternate row colors */
}

.conturi-table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.details-icon {
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
}
