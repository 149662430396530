.comenzi {
  padding: 20px;
  background-color: #f8f9fa; /* Light background color for the container */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
  max-width: 1200px; /* Limit the width to make the table more readable */
  margin: auto;
}

.comenzi h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.date-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.date-navigation button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.date-navigation button:hover {
  background-color: #0056b3;
}

.date-navigation span {
  font-size: 1.5em;
  color: #333;
}

.comenzi table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to table */
}

.comenzi table th, .comenzi table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
  color: #333;
}

.comenzi table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.comenzi-table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Slight alternate row color */
}

.comenzi-table tbody tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.info-icon {
  font-size: 1.5em;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.info-icon:hover {
  color: #0056b3;
}