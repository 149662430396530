/* src/Soferi.css */
.soferi {
    padding: 20px;
  }
  
  .soferi h1 {
    margin-bottom: 20px;
  }
  
  .soferi form {
    margin-bottom: 20px;
  }
  
  .soferi input {
    margin-right: 10px;
    padding: 5px;
  }
  
  .soferi button {
    padding: 5px 10px;
  }
  
  .soferi table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .soferi th, .soferi td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .soferi th {
    background-color: #f2f2f2;
  }
  
  .soferi td span {
    cursor: pointer;
  }
  
  .soferi td input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
  }
  