/* General styles */
.page_orderstatus {
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.status-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--color-golden);
  margin-bottom: 2rem;
}

.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-black);
  padding: 4rem 6rem;
  justify-content: center;
}

.status-node {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  text-align: center;
  color: var(--color-white);
  position: relative;
}

.status-node.active {
  border: 2px solid var(--color-golden);
  border-radius: 50%;
  padding: 10px;
}

.status-node:not(.active) {
  border: 2px solid #444;
  border-radius: 50%;
  padding: 10px;
}

.icon {
  font-size: 2rem;
}

.label {
  margin-top: 10px;
  font-size: 1rem;
  text-align: center;
}

.status-line {
  background-color: var(--color-golden);
  width: 50px;
  height: 2px;
}

.current-status {
  margin-top: 2rem;
  font-size: 1.5rem;
  color: var(--color-golden);
}

.cancel-reason {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: var(--color-white);
}

.order-items {
  margin-top: 2rem;
  text-align: left;
}

.order-items h2 {
  margin-bottom: 1rem;
  color: var(--color-golden);
}

.order-items ul {
  list-style-type: none;
  padding: 0;
}

.order-items li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-item-image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 1rem;
}

@media screen and (max-width: 767px) {
  .status-container {
    flex-direction: column;
    align-items: center;
  }

  .status-line {
    width: 2px;
    height: 50px;
  }

  .order-items li {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-item-image {
    margin-bottom: 10px;
  }
}