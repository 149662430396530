.app__account {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--color-black);
  color: white;
  padding: 20px;
  min-height: 100vh;
}

.account__menu {
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #1c1c1c;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  height: auto; /* Adjust height to content */
}

.account__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  color: var(--color-golden);
}

.account__header h2 {
  margin-top: 10px;
  font-size: 24px;
}

.menu-item {
  cursor: pointer;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.menu-item span {
  margin-left: 10px;
  font-size: 18px;
}

.menu-item:hover {
  background: var(--color-golden);
}

.logout {
  color: #ff4d4d;
}

.account__details {
  flex: 1;
  padding: 30px;
}

.active_option {
  font-weight: bold;
  background: var(--color-golden);
  color: var(--color-black);
}

@media (max-width: 768px) {
  .app__account {
    flex-direction: column;
    align-items: center; /* Center menu on small screens */
  }

  .account__menu {
    width: 100%; /* Make menu full width on small screens */
    margin-bottom: 20px; /* Add space below menu */
  }

  .account__details {
    width: 100%;
    padding: 20px;
  }
}


.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--color-white);
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #2c2c2c;
  color: white;
}

.form-group input:disabled {
  background: #1a1a1a;
}

.orders-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.order-card {
  background: #333;
  border: 1px solid var(--color-golden);
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.order-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
}

.order-card h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: var(--color-golden);
}

.order-card p {
  font-size: 16px;
  color: #ccc;
  margin-bottom: 10px;
}

.order-items {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.order-item {
  display: flex;
  gap: 15px;
  align-items: center;
  background: #2c2c2c;
  padding: 15px;
  border-radius: 8px;
  transition: background 0.3s ease;
}

.order-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid var(--color-golden);
}

.order-item:hover {
  background: #444;
}

.order-item div {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
}

.order-item div p {
  margin: 0;
  font-size: 14px;
}

.order-item div p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-golden);
}

.order-item div p:nth-child(2) {
  font-size: 14px;
  color: var(--color-crimson);
}

.order-item div p:nth-child(3) {
  color: #ddd;
}

@media (max-width: 768px) {
  .orders-list {
    grid-template-columns: 1fr;
  }
}

.custom__button {
  background-color: var(--color-golden);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 1.5rem;
  transition: background 0.3s ease, transform 0.3s ease;
}

.custom__button:hover {
  background-color: var(--color-crimson);
  transform: translateY(-3px);
}
