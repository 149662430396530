.cancellation-policy__container {
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: 'Open Sans', sans-serif;
    padding: 4rem 6rem;
    line-height: 1.6;
}

.cancellation-policy__title {
    color: var(--color-golden);
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.cancellation-policy__section {
    margin-bottom: 30px;
}

.cancellation-policy__section-title {
    color: var(--color-golden);
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.cancellation-policy__subsection {
    margin-bottom: 1rem;
}

.cancellation-policy__subsection-title {
    color: var(--color-golden);
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.cancellation-policy__section-content,
.cancellation-policy__subsection-content {
    margin-bottom: 1rem;
}
