.privacy__container {
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: 'Open Sans', sans-serif;
    padding: 4rem 6rem;
    line-height: 1.6;
}

.privacy__title {
    color: var(--color-golden);
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.privacy__content h2 {
    color: var(--color-golden);
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.privacy__content p {
    margin-bottom: 1rem;
}

.privacy__content ul {
    margin-left: 20px;
    list-style-type: disc;
}

.privacy__content ul li {
    margin-bottom: 10px;
}

.privacy__content strong {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .privacy__container {
      padding: 2rem;
    }
  
    .privacy__title {
      font-size: 2rem;
    }
  
    .privacy__content h2 {
      font-size: 1.5rem;
    }
  }
  