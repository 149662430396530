.terms__container {
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: 'Open Sans', sans-serif;
    padding: 4rem 6rem;
    line-height: 1.6;
  }
  
  .terms__title {
    color: var(--color-golden);
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .terms__content h2 {
    color: var(--color-golden);
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .terms__content p {
    margin-bottom: 1rem;
  }
  
  @media screen and (max-width: 768px) {
    .terms__container {
      padding: 2rem;
    }
  
    .terms__title {
      font-size: 2rem;
    }
  
    .terms__content h2 {
      font-size: 1.5rem;
    }
  }
  