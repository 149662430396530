.management__orders {
  padding: 40px;
  background-color: #e9ecef;
  font-size: 18px; /* Increase the base font size */
  line-height: 1.6; /* Improve readability */
}

.incoming__orders, .today__orders {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px; /* More space between cards */
}

.order__card {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out;
}

.order__card:hover {
  transform: translateY(-5px); /* Subtle hover effect */
}

.order__card h3 {
  margin-bottom: 15px;
  font-size: 24px; /* Larger heading size */
}

.order__card p {
  margin: 8px 0;
}

.admin-custom-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 15px 25px; /* Increase button size */
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 18px; /* Larger button text */
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Ensure consistent spacing */
}

.admin-custom-button:hover {
  background-color: #0056b3;
}

.modal {
  background-color: #ffffff;
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  font-size: 18px; /* Ensure consistency in modal */
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-textarea {
  width: 100%;
  height: 120px; /* Larger textarea for better usability */
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 15px;
  font-size: 16px;
}

.start-monitoring-banner {
  background-color: #007bff;
  border: 2px solid #0056b3;
  padding: 30px;
  text-align: center;
  border-radius: 12px;
  margin: 20px auto;
  width: 80%;
  color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  font-size: 20px;
}

.start-monitoring-button {
  background-color: #ffffff;
  color: #007bff;
  border: 2px solid #0056b3;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.start-monitoring-button:hover {
  background-color: #f8f9fa;
}
