/* src/Meniu.css */
.meniu {
  padding: 20px;
}

.meniu form {
  margin-bottom: 20px;
}

.meniu input {
  margin-right: 10px;
  padding: 5px;
}

.meniu button {
  padding: 5px 10px;
}

.category {
  margin-bottom: 40px;
}

.category h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  cursor: pointer;
}

.category h2 svg {
  margin-left: 10px;
  cursor: pointer;
}

.category-edit {
  display: flex;
  align-items: center;
}

.category-edit input {
  margin-right: 10px;
}

.product {
  margin-bottom: 20px;
}

.product h3 {
  margin: 0 0 10px 0;
}

.product img {
  max-width: 100px;
  height: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
}

table th {
  background-color: #f2f2f2;
}

.product-image-wrapper {
  position: relative;
  display: inline-block;
  width: 100px;  /* Set a consistent width */
  height: 100px; /* Set a consistent height */
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
}

.product-image-wrapper:hover .product-image {
  opacity: 0.7;
}

.product-image-wrapper:hover .product-image-overlay {
  opacity: 1;
}

.product-image-overlay svg {
  color: white;
  font-size: 24px;
}

.editable-field {
  cursor: pointer;
}

.editable-field input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
}

.toggle-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  float: right;
  /* Align to the right */
  margin-left: auto;
  /* Push the button to the right */
}

.toggle-button.active {
  background-color: #0056b3;
}

.meniu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.meniu-header form {
  flex-grow: 1; /* This makes the form take up all the remaining space */
}

.edit-mode-toggle {
  display: flex;
  align-items: center;
  margin-left: auto; /* Align the toggle to the right */
  font-size: 16px;
}

.toggle-text {
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.3s ease;
}

.toggle-text.active {
  border-bottom: 2px solid #007bff; /* Underline the active text */
  color: #007bff;
}

.category-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  cursor: pointer;
}

.category-header.edit-mode {
  justify-content: flex-start; /* Align to the left in edit mode */
}

.reorder-handle {
  cursor: pointer;
  margin-right: 10px;
}

.category-header.edit-mode .reorder-handle {
  display: inline; /* Only show the handle in edit mode */
}

.category-header:not(.edit-mode) .reorder-handle {
  display: none; /* Hide the handle when not in edit mode */
}

.category-actions {
  display: flex;
  align-items: center;
}

.category-actions .edit-icon,
.category-actions .delete-icon {
  margin-left: 10px;
  cursor: pointer;
}

.category-actions .delete-icon {
  color: red; /* Make the delete icon red */
}

.product-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;  /* Adjust width based on the space you need for icons */
}

.product-actions svg {
  cursor: pointer;
  margin-right: 5px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 400px;  /* Width of the modal */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.modal h2 {
  margin-top: 0;
}

.modal label {
  display: block;
  margin-bottom: 10px;
}

.modal input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.modal button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.modal button:last-child {
  background-color: #ccc;
  color: black;
}

.generate-pdf-button {
  background-color: #007bff; /* Matching blue color from the admin panel */
  color: #fff; /* White text color */
  padding: 10px 20px; /* Padding to match other buttons */
  border-radius: 5px; /* Rounded corners */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size to match other buttons */
  font-weight: bold; /* Bold text to stand out */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  margin-left: 20px; /* Adjust the margin to align with other elements */
  margin-right: 10px;
}

.generate-pdf-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}