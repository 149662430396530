/* src/components/ForgotPassword.css */
.app__forgotpassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 6rem;
    background: var(--color-black);
  }
  
  .app__forgotpassword h1 {
    margin-bottom: 2rem;
    color: var(--color-white);
  }
  
  .app__forgotpassword-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }
  
  .app__forgotpassword-form input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-family: var(--font-alt);
    font-size: 16px;
    color: black;
  }
  
  .custom__button {
    background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .link-button {
    background: none;
    border: none;
    color: var(--color-crimson);
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;
    padding: 0;
    font-family: var(--font-alt);
  }
  
  .message {
    color: green;
    margin-bottom: 1rem;
  }
  
  .error {
    color: red;
    margin-bottom: 1rem;
  }
  