.notification-bar {
    background-color: #ffcc00; /* Yellow background to catch attention */
    color: #333; /* Dark text for contrast */
    text-align: center;
    padding: 10px;
    font-weight: bold;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.notification-link {
    color: #007bff;
    text-decoration: none;
    margin: 0 15px;
}

.notification-link:hover {
    text-decoration: underline;
}
