/* MeniulZilei.css */
.week-title {
  text-align: center;
  margin-bottom: 20px;
}

.week-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.week-navigation button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 18px; /* Make the text bigger */
  padding: 12px 8px; /* Adjust padding for more balance */
  text-align: center; /* Center align the headers */
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  resize: none;
}

/* Button Styling */
.printable-content button {
  background-color: #007bff;
  /* Modern blue button color */
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
}

.printable-content button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.printable-content button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Print Styling */
@media print {
  @page {
    margin: 0;
    size: landscape;
  }

  a[href]:after {
    content: none !important;
  }

  body * {
    visibility: hidden;
  }

  .printable-content,
  .printable-content * {
    visibility: visible;
  }

  .printable-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    page-break-before: always;
    page-break-after: always;
  }

  .week-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .week-navigation, 
  .printable-content button {
    display: none !important;;
  }

  /* Ensure the table fits nicely on an A4 page */
  table {
    page-break-inside: avoid;
    width: 100%;
  }
}

/* MeniulZilei.css */
.table-cell {
  padding: 0 !important;
}

.textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 8px; /* Adjust padding as needed */
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
