.sticky-wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
}


.app__maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  text-align: center;
}

.app__maintenance-content {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.app__maintenance-content h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.app__maintenance-content p {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}