.admin {
  display: flex;
  font-family: 'Roboto', sans-serif; /* Use a modern, clean font */
}

.admin__sidebar {
  width: 250px;
  background-color: #1e1e2f; /* Darker background for sidebar */
  color: #fff;
  height: 100vh;
  padding: 20px;
  position: fixed; /* Keep the sidebar fixed on the left */
}

.admin__sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin__sidebar li {
  margin-bottom: 15px; /* Increase spacing for better readability */
}

.admin__sidebar a {
  color: #ddd;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease; /* Smooth transition effect */
}

.admin__sidebar a:hover,
.admin__sidebar .active a {
  color: #1e90ff; /* Highlight color on hover */
}
.admin__sidebar .active {
  border-left: 4px solid #1e90ff; /* Optional: blue border to indicate active */
}

.admin__content {
  flex: 1;
  margin-left: 270px; /* Adjust for the fixed sidebar */
  padding: 30px;
  background-color: #f8f9fa; /* Light background for content area */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff; /* White background for tables */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

table th, table td {
  padding: 12px 15px; /* Increase padding for better spacing */
  text-align: left;
}

table th {
  background-color: #007bff; /* Modern blue header */
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

table tr:nth-child(even) {
  background-color: #f2f2f2; /* Alternate row colors */
}

table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.admin-custom-button {
  background-color: #007bff; /* Modern blue buttons */
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.admin-custom-button:hover {
  background-color: #0056b3; /* Darken on hover */
}

.admin-custom-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
