.app__menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 6rem;
}

.app__menu-categories {
  flex: 1;
  margin-right: 2rem;
}

.app__menu-categories h2 {
  margin-bottom: 1rem;
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.app__menu-categories ul {
  list-style: none;
  padding: 0;
}

.app__menu-categories li {
  position: relative;
  margin-bottom: 1rem;
  font-family: var(--font-base);
  font-size: 23px;
  color: var(--color-white);
  cursor: pointer;
  transition: color 0.3s ease;
}

.app__menu-categories li:hover .underline {
  width: 100%;
}

.app__menu-categories li .underline {
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 2px;
  width: 0;
  background-color: var(--color-golden);
  transition: width 0.3s ease;
}

.app__menu-categories li.selected {
  color: var(--color-golden);
}

.app__menu-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 1rem;
}

.app__menu-item-card {
  display: grid;
  grid-template-rows: 200px 1fr auto; 
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--color-white);
  border-radius: 8px;
  text-align: center;
  padding: 1rem;
}

.app__menu-item-card img {
  grid-row: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.app__menu-item-card .content {
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.app__menu-item-card .details {
  flex-grow: 1;
}

.app__menu-item-card h3 {
  margin-bottom: 0.5rem;
}

.app__menu-item-card p {
  margin-bottom: 0.5rem;
}

.app__menu-item-card .footer {
  grid-row: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.app__menu-item-card .footer p {
  margin: 0;
  color: var(--color-golden);
}

.app__menu-item-card .details p.size {
  color: grey; /* Change size color to grey */
  margin-bottom: 0.5rem;
}

.custom__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 1rem;
}

@media screen and (max-width: 1097px) {
  .app__menu {
    flex-direction: row;
    justify-content: space-between;
  }

  .app__menu-categories {
    flex: 0.75; /* Reduce the width of the categories block */
    margin-right: 1rem;
  }

  .app__menu-items {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    gap: 0.5rem; /* Adjust the gap for smaller screens */
  }

  .app__menu-item-card {
    padding: 1rem;
  }
}

@media screen and (max-width: 842px) {
  .app__menu {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .app__menu-categories ul {
    display: flex;
    flex-direction: column;
  }

  .app__menu-categories li {
    margin-bottom: 2rem;
  }

  .app__menu-items {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .app__menu-item-card img {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  .app__menu-item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .app__menu-item-card .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .app__menu-item-card .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

