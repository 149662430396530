.settings {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif;
}

.settings h1 {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 25px;
  color: #333;
}

.settings__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.setting-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.setting-group input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.setting-group input:focus {
  border-color: #007bff;
  outline: none;
}

.save-button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.save-button:hover {
  background-color: #0056b3;
}
