.order-details {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .order-details h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .order-panel {
    padding: 20px;
    border-radius: 8px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .order-panel h2 {
    margin-top: 20px;
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
  }
  
  .order-panel p {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
  }
  
  .items-list {
    display: flex;
    flex-direction: column;
  }
  
  .item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 8px;
  }
  
  .item-photo {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .item p {
    margin: 5px 0;
    font-size: 14px;
  }
  