.account-details {
    padding: 20px;
  }
  
  .account-info {
    margin-bottom: 20px;
  }
  
  .account-info h2 {
    margin-bottom: 10px;
  }
  
  .orders-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .orders-table th, .orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .orders-table th {
    background-color: #007bff;
    color: #fff;
  }
  
  .orders-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .orders-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .orders-table a {
    color: #007bff;
    text-decoration: none;
  }
  
  .orders-table a:hover {
    text-decoration: underline;
  }
  