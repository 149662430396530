.app__checkout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 6rem;
  background: var(--color-black);
}

.app__checkout-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.app__checkout-items {
  flex: 2;
  margin-right: 2rem;
}

.app__checkout-item {
  display: flex;
  margin-bottom: 2rem;
  background: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 8px;
}

.app__checkout-item img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
}

.app__checkout-item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app__checkout-summary {
  flex: 1;
  background: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  border-radius: 8px;
  text-align: left;
}

.app__checkout-summary h2 {
  margin-bottom: 1rem;
}

.app__checkout-summary p {
  margin-bottom: 1rem;
}

.app__checkout-payment {
  margin-top: 2rem;
}

.app__checkout-payment form {
  display: flex;
  flex-direction: column;
}

.app__checkout-payment form input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-family: var(--font-alt);
  font-size: 16px;
  color: black; /* Set text color to black */
}

.custom__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .app__checkout {
    flex-direction: column;
  }

  .app__checkout-content {
    flex-direction: column;
  }

  .app__checkout-items {
    margin-bottom: 2rem;
  }

  .app__checkout-item {
    flex-direction: column;
  }

  .app__checkout-summary {
    padding: 1rem;
  }
}
.quantity-controls {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantity-btn {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  line-height: 28px;
  font-size: 16px;
  padding: 0.2rem 1rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 5px;
}

.quantity {
  font-size: 20px;
  font-family: var(--font-base);
  color: var(--color-white);
}

.payment-method label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  color: white; /* Change text color to white */
}

input[type="radio"] {
  opacity: 0; /* Hide the default radio button */
  position: absolute;
}

.custom-radio {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--color-crimson);
  margin-right: 10px;
  position: relative;
}

.custom-radio::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background: var(--color-crimson);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

input[type="radio"]:checked + .custom-radio::after {
  opacity: 1;
}

.delivery-method {
  margin-top: 20px;
}

.delivery-method label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  color: white;
}

.custom-radio {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--color-crimson);
  margin-right: 10px;
  position: relative;
}

.custom-radio::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background: var(--color-crimson);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

input[type="radio"]:checked + .custom-radio::after {
  opacity: 1;
}

.app__checkout-payment form input[type="text"], 
.app__checkout-payment form textarea,
.app__checkout-payment form input[name="address"] {
  color: black !important; /* Set text color to black */
  background-color: white;
}

.app__checkout-payment textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-family: var(--font-alt);
  font-size: 16px;
  color: black; /* Set text color to black */
}