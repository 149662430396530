.app__header {
    background-color: var(--color-black);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 117px;
    font-size: 90px;
    word-break: break-word;
}

/* For medium screens */
@media screen and (max-width: 850px) {
    .app__header-h1 {
      font-size: 70px;
      line-height: 80px;
    }
  }
  
  /* For small screens */
  @media screen and (max-width: 650px) {
    .app__header-h1 {
      font-size: 50px;
      line-height: 60px;
    }
  }
  
  /* For extra small screens */
  @media screen and (max-width: 450px) {
    .app__header-h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }

.app__header-img img {
    width: 80%;
}